.agreement {
  border-left-width: 10px;
}

.agreement.Active {
  border-left-color: #61bd24;
  background-color: #61bd242f;
  border-left-width: 5px;
}

.agreement.Pending {
  border-left-color: #bdb324;
  background-color: #bdb3242f;
  border-left-width: 5px;
}

.agreement.Expired {
  border-left-color: #bd2424;
  background-color: #bd24242f;
  border-left-width: 5px;
}

.agreement.NoValidity {
  border-left-color: #a3a3a3;
  background-color: #a3a3a32f;
  border-left-width: 5px;
}
