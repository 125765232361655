.ContentDistribution {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.ContentDistribution > div:first-child {
    flex: 1;
}

.ContentDistribution > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 15em;
}