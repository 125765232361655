.CalendarContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100vw - 18em);
    margin-top: 20px;
    overflow: auto;
    background-color: rgb(255, 255, 255);
    box-shadow: inset #247dbdbe 0px 0px 9px -1px;
    padding: 2em;
    border-radius: .4em;
    border: solid 2px #247dbdbe;
}

.ActivityControls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.ActivityControls > * {
    margin-right: 1em;
}

.DateRangeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.DateRangeContainer > div {
    margin-right: 0.5em;
}