.NoDataSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 20px;
    text-align: center;
    margin-bottom: 3em;
    margin-top: 3em;
}

.CycleListItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    padding: 0.5em;
    margin: 0.5em 0.5em 0 0;
    border: solid 1px #c0c0c0;
    border-radius: 5px;
}

.CycleListItem > div:nth-child(2) {
    flex: 1;
    align-self: flex-start;
}

.CycleListItem > div:last-child {
    flex: 0;
    align-self: center;
}

.CycleListItem:hover {
    background-color: #f0f0f0;
}

.CycleIndex{
    width: 2em;
    height: 100%;
    text-align: center;
    margin-left: -0.5em;
    border-right: solid 1px lightgray;
    margin-right: 0.5em;
}

.CycleIndex > span{
    writing-mode: vertical-lr; 
    transform: rotate(180deg);
    height: 6em;
}