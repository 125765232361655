.consumptionDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}

.consumptionDetails > label {
    font-size: 1em;
    font-weight: normal;
}

.consumptionDetails > span {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0.3em;
}

.consumptionValues {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}

.consumptionValues > label {
    font-size: 1.1em;
    font-weight: normal;
}

.consumptionValues > span {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0.3em;
    align-self: self-end;
    text-align: right;
    border-bottom: dotted 1px #aeaeae;
}

.consumptionConceptValue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;
    border-bottom: dotted 1px #cacaca;
}

.consumptionConceptValue > label {
    font-size: 1.1em;
    font-weight: normal;
}

.consumptionConceptValue > span {
    font-size: 1.2em;
    font-weight: bold;
}

.consumptionSubTotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;
    border: solid 1px #aeaeae;
    padding: 1em;
}

.consumptionSubTotal > label {
    font-size: 1.1em;
    font-weight: normal;
}

.consumptionSubTotal > span {
    font-size: 1.2em;
    font-weight: bold;
}

.consumptionGrandTotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;
    border: solid 1px #aeaeae;
    padding: 1em;
}

.consumptionGrandTotal > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin-bottom: 1em;
}

.consumptionGrandTotal > div:last-child {
    margin-bottom: unset;
}

.consumptionGrandTotal > div > div > span {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 1.1em;
    font-weight: bold;
    justify-content: flex-end;
}

.consumptionGrandTotal > div > div > span > small {
    margin-right: 1em;
}

.consumptionGrandTotal > div:first-child > label {
    font-size: 1.4em;
    font-weight: normal;
}

.consumptionGrandTotal > div:first-child > span {
    font-size: 1.4em;
    font-weight: bolder;
}

.consumptionHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.consumptionPerson > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5em;
}

.consumptionPerson > div > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.consumptionPerson > div > span:first-child {
    font-size: 1.2em;
    font-weight: normal;
    margin-right: 0.5em;
}

.consumptionPerson > div > div > span {
    font-size: 1.1em;
    font-weight: 600;
}

table.values {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    border: solid 1px #aeaeae;
    border-radius: 4px;
}

table.values thead {
    background-color: #d8d7d7;
    border: solid 1px #aeaeae;
    padding: 0.5em;
}

table.values thead  th {
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    border-left: solid 1px #aeaeae;
}

table.values tbody  th {
    background-color: #ececec;
    font-weight: bold;
    border-bottom: solid 1px #aeaeae;
}

table.values tbody  td {
    padding: 0.3em;
    font-size: 1em;
    font-family: Tahoma, Verdana, sans-serif;
    font-weight: normal;
    background-color: #ffffff;
    border-left: solid 1px #aeaeae;
    border-bottom: solid 1px #aeaeae;
    border-top: solid 1px #aeaeae;
}

.RelatedConceptContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.RelatedConceptContainer > div > span {
    font-size: medium;
    font-weight: 600;
}

.RelatedConceptContainer > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.5em;
}

.RelatedConceptContainer > div > div:last-child {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.5em;
}

.RelatedConceptContainer > div > div > button {
   margin-right: 1em;
}
