.PlanItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  height: 100%;
  padding: 0 10px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  padding-bottom: 1em;
}

.PlanItem:last-child {
  margin-bottom: 0;
  border-bottom: unset;
}

.PlanItem > label {
  cursor: pointer;
}

.TextWithIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.TextWithIcon > i {
  margin-right: 0.5em;
}

.TextWithIcon:hover > i {
  color: #247dbd;
}

.TextWithIcon:hover {
  color: #247dbd;
}
