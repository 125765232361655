.SummaryStatisticComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SummaryStatisticComponent > div {
    min-width: 1.5em;
    text-align: center;
    padding-bottom: .2em;
}

.SummaryStatisticComponent > div:first-child {
    margin: unset;
}

.badget {
    margin-right: 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: #fff;
    padding: .2px 5px;
    border-radius: 3px;
    border: solid .1em lightgray;
}

.badget.red {
    border-left: solid 5px #dc3545;
}

.badget.green {
    border-left: solid 5px #28a745;
}

.badget.yellow {
    border-left: solid 5px #e5e91f;
}

.badget.gray {
    border-left: solid 5px #616161;
}