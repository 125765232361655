.ContractAgentAssociationItem {
    display: flex;
    flex-direction: row;	
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.ContractAgentAssociationItem:last-child {
    border-bottom: none;
}