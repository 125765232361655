.SummaryItemContainer {
    display: flex;
    flex-direction: column;
    border: solid 1px lightgray;
    border-radius: 3px;
    padding: .2em .6em;
    margin-bottom: .3em;
}

.SummaryInfo {
    width: 100%;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    flex-direction: row;
}

.AditionalInfomationContainer {
    display: flex;
    flex-direction: row;
    align-items: self-start;
    justify-content: space-between;
    overflow: hidden;
    visibility:hidden;
    opacity:0;
    max-height: 0;
    transition:max-height 0.3s ease, visibility 0.3s linear, opacity 0.3s linear;    
    transition-delay: 500ms;
}

.SummaryItemContainer:hover > .AditionalInfomationContainer {
    display: flex;
    visibility: visible;
    opacity: 1;
    max-height: 100em;
    border-top: dashed 1px lightgray;
}

.SummaryItemActions {
    display: flex;
    align-self: flex-start;
}

.SummaryItemContainer:hover {
    background-color: rgb(244, 248, 252);
}

.SummaryItemContainer > .SummaryItemActions {
    margin-left: auto;
}

.SummaryItemContainer.warning {
    /* border-color: #ebd517; */
    border-left: solid 5px #ebd517;
}

ul.TotalList {

    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul.TotalList li {
    display: flex;
    justify-content: space-between;
    padding: .2em 0;
    flex-direction: column;
    align-items: self-end;
}

.InformacionContainer {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-between;
    width: 100%;
    padding: .5em;
}

.SummaryStatistics {
    margin-top: .3em;
}