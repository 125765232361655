.Table td > ul > li {
  list-style: none;
  padding: 0;
  margin: 0.5em 0;
}

.Table td > div {
  display: flex;
}

.Table th {
  min-width: 10em !important;
  max-width: 15em !important;
  text-align: left;
}

.Table td {
  vertical-align: top;
}

.Table th:first-child,
.Table td:first-child {
  width: 1em;
    min-width: unset !important;
}



.Table th:last-child,
.Table td:last-child {
  position: sticky;
  right: 0;
  width: 1em;
}

.Table th:last-child:before,
.Table td:last-child:before {
  content: "\00a0";
  display: block;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0px;
  bottom: 0;
  left: 0;
  z-index: -1;
  
  
}

.Table td:last-child:before {
  box-shadow: -1px 0px 4px 0px #c5c5c5;
  /* border-right: 1px solid #cccccc; */
  /* border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em; */
}

.Table tr:hover {
  background: rgb(240, 240, 240);

}

.Table tr:hover > td:last-child:before {
  background: rgb(240, 240, 240) !important;
}

.AvailabilityListContent {
  display: flex;
  overflow: auto;
  max-width: calc(100vw - 13em);
}