.ActivityContainer {
    display: flex;
    flex-direction: row;
}

.ActivityBox {
    display: flex;
    flex-direction: row;
    margin: 0 0 .2rem 0;
    border: 1px solid #247dbdbe;
    border-radius: 3px;
}

table.ActivityGrid {
    border-collapse: collapse;
}

table.ActivityGrid th {
    text-align: center;
    max-width: 1em;
    font-size: 0.5em;
}

table.ActivityGrid td {
    padding: 0em 0.2em;
    max-width: 1em 
    
}

table.ActivityGrid tr {
    max-width: 1em 
}

table.ActivityGrid td > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1em ;
    height: 1em ;
    color: white;
    /* background-color: #f9f9f9; */

}

.ActivityMonthContainer {
    display: flex;
    flex-direction: column;
    margin: 0 .5em .5rem 1em;
    border-left: solir 1px #247dbdbe;
}

.ActivityBox.activeVisit {
    background-color: #247dbdbe;
}

.ActivityBox.inactive {
    border: unset;
}

