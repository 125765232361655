.ConsumptionListContainer {
    border: 1px solid #b3b3b3;
    padding: 1em;
    overflow: hidden;
}

.fui-TableBody {
    max-width: 100vw;
}

.ConsumptionFilters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1em;
}

.ConsumptionFilters > div {
    flex-direction: column;
    margin-right: 1em;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
}


 /*
    consumptions status:
        NotSettled = 0,
        Pending = 1,
        Payee = 2,
        Settled = 3,
        Rejected = 4,
        Ignored = 5,
     */

/* consumption status */


.status-not-settled {
    background-color: #ffcc00; /* Yellow */
    color: #000000;
}

.status-pending {
    background-color: #ff9100; /* Orange */
    color: #ffffff;
}

.status-payee {
    
    background-color: #39b828; /* Green */
    color: #ffffff;
}

.status-settled {
    background-color: #3399ff; /* Blue */
    color: #ffffff;
}

.status-rejected {
    background-color: #ff3300; /* Red */
    color: #ffffff;
}

.status-ignored {
    background-color: #cccccc; /* Grey */
    color: #000000;
}

.status-unknown {
    background-color: #666666; /* Dark Grey */
    color: #ffffff;
}

.status-mark {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-right: 0.5em;
    vertical-align: middle;
}

.status-to-settle {
    background-color: #0044ff; /* Yellow */
    color: #000000;
}

.ConsumptionFiltersContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border: solid 1px #b3b3b3;
    margin: 0.5em 0;
}

.ConsumptionFiltersContainer > div {
    flex-direction: column;
    margin-right: 1em;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.ConsumptionFiltersContainer > div  label {
    padding: 0px 0px;
    font-weight: 600;
}

.ConsumptionMarks {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1em;
}

.consumption-ignored {
    background-color: #feffc2;
}

.fui-Badge {
    margin-left: 1em;
}


/* Styles for rows by status */

.status-not-settled-row {
    background-color: #ffcc00; /* Yellow */
    color: #000000;
}

.status-pending-row {
    background-color: #ff9100; /* Orange */
    color: #ffffff;
}

.status-payee-row {
    
    background-color: #39b82813; /* Green */
    color: #ffffff;
}

.status-settled-row {
    background-color: #3399ff0a; /* Blue */
    color: #ffffff;
}

.status-rejected-row {
    background-color: #ff3300; /* Red */
    color: #ffffff;
}

.status-ignored-row {
    background-color: #cccccc1c; /* Grey */
    color: #000000;
}

.status-unknown-row {
    background-color: #66666623; /* Dark Grey */
    color: #ffffff;
}

.status-to-settle-row {
    background-color: #1f58f51c; /* Yellow */
    color: #000000;
}

