.TabContent > span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.tabContentDivider {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
    margin: 10px 0;
}

.fui-TabList > button {
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;
    padding: 1em;
    border: solid 1px #e0e0e0;
    border-bottom: unset;
    border-radius: 5px 5px 0 0;
    margin-right: .5em;
    margin-top: 2em;
    background-color: white;
}

.SectionTabs {
    margin-left: 3em;
}

.SectionTabs > .Section {
    margin-top: 0;
    margin-left: -2em;
}

.DateRangeContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.DateRangeContainer > label {
    float: right;
}

.DateRangeElement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1em;
}

.DateRangeElement > div {
    margin-right: 0.3em;
}