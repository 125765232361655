.CommissionAgentConceptContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    cursor: pointer;
}

.CommissionAgentConceptContainer:hover {
    background-color: #f0f0f0;
}

.CommissionAgentConceptContainer.disabled {
    color: #b0b0b0;
    cursor: not-allowed;
}

.CommissionAgentConceptContainer.disabled > * {
    color: #b0b0b0;
    cursor: not-allowed;
}

.CommissionAgentConceptContainer.disabled:hover {
    background-color: #f9f9f9;
}