.headerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.headerContent > div:first-child {
  flex: 1;
}

.agreement.Active {
  border-radius: 3px;
  text-align: center;
  color: #61bd24;
  font-size: large;
}

.agreement.Pending {
  border-radius: 3px;
  text-align: center;
  color: #bdb324;
  font-size: large;
}

.agreement.Expired {
  border-radius: 3px;
  text-align: center;
  color: #bd2424;
  font-size: large;
}

.agreement.NoValidity {
  border-radius: 3px;
  text-align: center;
  color: #a3a3a3;
  font-size: large;
}
