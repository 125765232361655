.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* input,
button,
textarea {
  border-radius: 2px !important;
} */

.actionButton {
  border-radius: 3px;
  border-color: #247dbd;
  color: #fff;
  background-color: #247dbd;
  opacity: 1;
  filter: alpha(opacity=100);
}

.optionButton {
  border-radius: 3px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.deleteButton {
  border-radius: 3px;
  border-color: #bf482b;
  color: #fff;
  background-color: #bf482b;
  opacity: 1;
  filter: alpha(opacity=100);
}

.deleteButton:hover {
  border-color: #b93918;
  color: #fff;
  background-color: #b93918;
}

.ConfirmAction {
  background-color: #20ad9b;
  border-color: #20ad9b;
  margin-right: 1em;
  border-radius: 3px;
}

.OptionAction {
  background-color: #247dbd;
  margin-right: 1em;
  border-radius: 3px;
}

.OtherOption {
  background-color: #a6a6a6;
  border-color: #ccc;
  color: white;
  margin-right: 1em;
  border-radius: 3px;
}

.pageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  height: 50px;
  border-bottom: solid 1px lightgray;
}

.content {
  flex: 1;
  overflow: auto;
  background-color: rgb(247, 247, 247);
}

.footerBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  position: sticky;
  bottom: 0;
  border-top: solid 1px lightgray;
  margin-right: 1em;
}

.footerBar > span, a, img {
  margin: 0 3px;
}

.leftColumn {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.content > .PageHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5em 1em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgb(250, 250, 250);
  -moz-box-shadow: 0px 10px 26px -19px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 26px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 26px -19px rgba(0, 0, 0, 0.75);
  z-index: 100000;
}

.content .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5em;
  margin: 0 0 0 1em;
}

.content > .PageHeader > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 0.5em;
}

.content > .PageHeader > div > label {
  font-size: 1.3em;
  font-weight: bold;
}

.content > .PageHeader > button {
  background-color: #247dbdbe;
  color: rgb(247, 247, 247);
  border-radius: 3px;
  padding-left: 0.1em;
  margin-top: 0.2em;
}

.content .header > h1 {
  font-size: 1.3em;
  font-weight: bold;
}

.content .header > h2 {
  font-size: 1em;
  font-weight: bold;
  margin: 1em 0 0 0;
}

.Section {
  margin: 1em;
  border: solid 1px lightgray;
  padding: 1em;
  background-color: white;
  border-radius: 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 6px 9px 0px;
}

.Section.Internal {
  box-shadow: rgba(100, 100, 111, 0.2) 1px 1px 8px 0px;
  margin: unset;
}

.Section-principal {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
}

.SectionControls {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* margin: 0 0 1em 0; */
}

.SectionControls > button {
  margin-right: 0.5em;
}

.SectionControls > .ms-SearchBox  {
  margin: 0;
  float:left;
}

.ms-Panel-footer{
  background-color: white;
  border-top: solid 1px lightgray;
}

.danger {
  display: flex;
  padding: 0 1em;
  border-left: solid 1px lightgray;
  align-items: center;
  justify-content: center;
  float: right;
}

.danger > button {
  color: #d82c12;
}

.SectionWidget {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.pullLeft {
  justify-content: flex-start;
}

.spaceBetween {
  justify-content: space-between;
}

.Section-column {
  flex: 0;
  min-width: 15em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Table {
  width: 100%;
  font-size: small;
  border-spacing: 0px;
  border-spacing: 0 10px;
}

.Table > thead {
  color: lightgray;
  font-weight: 100;
  text-align: left;
}

.Table > tbody > tr > td.primary {
  font-weight: bold;
}

.Table > tbody > tr > td {
  border: solid 1px lightgray;
  border-style: solid none;
  padding: 10px;
}
.Table > tbody > tr > td:first-child {
  border-left-style: solid;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.Table > tbody > tr > td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  min-width: 10em;
}

.badge {
  padding: 10px;
  background-color: lightgray;
  border-radius: 3px;
  margin-right: 0.5em;
  text-align: center;
  text-transform: uppercase;
}

.badge.red {
  background-color: rgb(214, 66, 66);
  color: rgb(247, 247, 247);
}

.badge.green {
  background-color: #20ad9b;
  color: rgb(247, 247, 247);
}

.badge.blue {
  background-color: #247dbd;
  color: rgb(247, 247, 247);
}

.badge.orange {
  background-color: #e9e282;
  color: rgb(0, 0, 0);
}

.badge.mini {
  padding: 4px 15px;
  border-radius: 3px;
  margin-right: 0.2em;
  text-align: center;
  font-size: small;
  text-transform: capitalize;
}

.TextDecorated {
  color: #0c4b79;
  background-color: #c0e8ff;
  display: block;
  border-radius: .5em;
  padding: 0.5em 0.5em;
}

.status {
  display: block;
  text-align: center;
}

.SideLayoutPage {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  max-width: 90vw;
}

.SideLayoutPage > div:first-child {
  display: flex;
  flex-direction: column;
  max-width: 15em;
  margin-right: 2em;
}

.SideLayoutPage > div:last-child {
  flex: 1;
}

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loader.center {
  justify-content: center;
}

.notfoundmessage {
  text-align: center;
}

.actionHeader {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.actionHeader > div.Title {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.actionHeader > div.controls {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.actionHeader > div > label {
  font-size: x-large;
}

.actionHeader > div > div > div > div {
  margin: 0.4em 0;
}

.actionHeader > div input {
  font-size: x-large;
}

.actionHeader > div > button {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.actionHeader > div > input {
  display: block;
}

.fieldCommands {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fieldCommands > :first-child {
  flex: 1;
}

.ms-Pivot {
  margin-bottom: 1.5em;
}

.flex1 {
  flex: 1;
}

.margin-bottom {
  margin-bottom: 1em;
}

.listHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0;
}

.ms-Dialog-header {
  background-color: lightgray;
}

.ms-MessageBar {
  border-radius: 3px;
  margin-bottom: 0.3em;
}

.ComponentHeaderControls {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: flex-start;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.NoData {
  text-align: center;
  font-size: large;
  font-weight: bold;
  color: lightgray;
}

.formColumns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.formColumns > div {
  flex: 1;
  margin-right: 1em;
  max-width: 34%;
}

.TextFieldValueModified {
  border-color: red;
}

.ms-SearchBox {
  border-radius: 3px;
  border: solid 1px lightgray;
}

.root-224::after {
  border-radius: 3px;
}

.ModalMargin {
  padding: 1em;
  border-radius: 3px;
}

.optionsColumn {
  width: 15em;
}

@keyframes rotating {
  from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.withRotation {
  -webkit-animation: rotating 2.5s linear infinite;
  -moz-animation: rotating 2.5s linear infinite;
  -ms-animation: rotating 2.5s linear infinite;
  -o-animation: rotating 2.5s linear infinite;
  animation: rotating 2.5s linear infinite;
}

.FormFooterActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 1em 1em;
}

.masterContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.Toast.LeftBorder {
  border-left: solid 5px lightgray;
}

.Toast.LeftBorder.red {
  border-left: solid 10px #dc626d;;
}

.Toast {
  min-width: 15em;
  -webkit-box-shadow: -14px 21px 24px -10px rgba(0,0,0,0.67);
  -moz-box-shadow: -14px 21px 24px -10px rgba(0,0,0,0.67);
  box-shadow: -14px 21px 24px -10px rgba(0,0,0,0.67);
}

.ms-Panel-content {
  padding-top: 1em;
  overflow: auto;
  background: /* Shadow covers */
  linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
  radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background: /* Shadow covers */
  linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
  radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.ScrollShadow {
  padding-top: 1em;
  overflow: auto;
  background: /* Shadow covers */
  linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
  radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background: /* Shadow covers */
  linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
  radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #9e9e9e #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 3px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 4px;
    border: 3px solid #ffffff;
  }

  .fui-MessageBarBody {
    max-width: 30vw;
  }

