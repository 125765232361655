.primary.Red {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(214, 66, 66);
}

.primary.Yellow {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(214, 204, 66);
}

.primary.Green {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(83, 214, 66);
}

.primary > p {
    font-weight: lighter;
}