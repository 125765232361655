/*
Protocol Status
_____________________
Active = 1,
Inactive = 2,
Suspended = 3,
Expired = 4,
Canceled = 5,
Terminated = 6
*/


.ProtocolStatus {
    border-left: solid 6px #5e5e5e;
}

.ProtocolStatus.Active {
    border-left: solid 6px #00a651;
}

.ProtocolStatus.Inactive {
    border-left: solid 6px #ffff00;
}

.ProtocolStatus.Suspended {
    border-left: solid 6px rgb(97, 120, 163);
}

.ProtocolStatus.Expired {
    border-left: solid 6px #040404;
}

.ProtocolStatus.Canceled {
    border-left: solid 6px rgb(171, 106, 106);
}

.ProtocolStatus.Terminated {
    border-left: solid 6px #f00;
}

.ProtocolHeaderDetails {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    margin-bottom: 10px;
}

.ProtocolHeaderDetails > div {
    margin-right: 1em;
}
