.PrincipalPageContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.PrincipalPageContent > div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: calc(100vh - 370px);
  margin: 1.5em;
}
