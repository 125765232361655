.VisitPanelContainer {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    border: solid 1px lightgray;
}

td::before {
    content: unset;
}