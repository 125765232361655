.LabelIcon {
    margin-right: 5px;
    font-size: 1.2rem;
    color: #0e54eb;
    padding: 0.2rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 1.5;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: #fff;
        background-color: #000;
    }
}

.verificationstatus {
    margin-right: 5px;
    font-size: 1.2rem;
    color: #45791a;
    padding: 0.2rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 1.5;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
}

.contractInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.8em;
    border-radius: 3px;
    background-color: #efefef;
    border: solid 1px lightgray;
    position: relative;
}

.DisassociateOption {
    position: absolute;
    right: 0;
}

.danger {
    color: red
}

button.danger {
    border: solid 1px rgb(187, 1, 1);
    background-color: rgb(187, 1, 1);
    color: white;
    border-radius: 3px;
}

button.danger:hover {
    background-color: rgb(199, 2, 2);
    color: white;
}