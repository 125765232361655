.consumption-detail-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1em;
}

.consumption-detail-menu-container > button {
    margin: 0 0.2em;
}