/*
Contract Status
_____________________
Active = 1,
Inactive = 2,
Suspended = 3,
Expired = 4,
Canceled = 5,
Terminated = 6
*/


.ContractStatus {
    border-left: solid 6px #5e5e5e;
    display: flex;
    flex-direction: row;
}

.ContractStatus.Active {
    border-left: solid 6px #00a651;
}

.ContractStatus.Inactive {
    border-left: solid 6px #ffff00;
}

.ContractStatus.Suspended {
    border-left: solid 6px rgb(97, 120, 163);
}

.ContractStatus.Expired {
    border-left: solid 6px #040404;
}

.ContractStatus.Canceled {
    border-left: solid 6px rgb(171, 106, 106);
}

.ContractStatus.Terminated {
    border-left: solid 6px #f00;
}

.ContractHeaderDetails {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    margin-bottom: 10px;
}

.ContractHeaderDetails > div {
    margin-right: 1em;
}

.ContractActions__Buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.ContractActions__Buttons > button {
    margin-bottom: 0.5em;
}

.ContractHeader {
    flex: 1;
}

.ContractActions {
    min-width: 10em;
}

.AddendaBadge {
    margin-left: 1em;
    background-color: #247dbd;
    color: #fff;
    padding: .2em .3em;
    border-radius: 5px;
}

