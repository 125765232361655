.ContractHeader_Title {
    display: flex;
    flex-direction: row;
}

.ContractHeader_Title > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 10px;
}

.ContractHeader_Title > div:first-child {
    flex: 1;
}

.ComponentControls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.ComponentControls > button {
    margin-bottom: .4em;
    border-radius: 3px;
}

button.Danger {
    border-color: #f44336;
    color: #f44336;
    
}

.ContentAlignedHorizontally {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}