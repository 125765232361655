.AddendumsContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.AddendumsRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: -webkit-fill-available;
}

.AddendumsRow > button {
    margin: 0 10px;
    border-radius: 6px;
}

.AddendumsRow > button:first-child {
    margin-left: 0;
}

.AddendumsRow > label {
    margin: 0 10px;
}

.AddendumsRow > button.Active {
    border: solid 2px #247dbd;
    /* add shadow */
    box-shadow: 0 0 10px #b1b1b1;

}

.CurrentAddendum {
    background-color: #c7f2ff;
}

.ExpiredAddendum {
    background-color: #e4e4e4;
}

.AddendumActions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.AddendumActions > button {
    margin: .3em 0;
}
