table.SettlemementGrid {
    width: 100%;
    border-collapse: collapse;
    border-radius: 3px;
}

table.SettlemementGrid  tr  {
    border-bottom: 1px dotted rgb(153, 153, 153);
}

table.SettlemementGrid  tr:last-child  {
    border-bottom: unset;
}

table.SettlemementGrid  tr:hover {
    background-color: #ceeaff;
    font-weight: 700;
}

table.SettlemementGrid  td, th {
    padding: .3em;
    /* max-width: 3em;
    min-width: 1em; */
}

table.SettlemementGrid td > label  {
    text-wrap: nowrap;
    padding: 5px 10px;
}

table.SettlemementGrid th  {
    text-wrap: nowrap;
    padding: 5px 10px;
}

table.SettlemementGrid td:hover > label {
    background-color: #247dbd;
    border-radius: 3px;
    color: white;
    font-weight: 700;
    
}

table.SettlemementGrid th {
    text-align: left;
    background-color: #f0f0f0;
    position: sticky;
    top: 0;
}

table.SettlemementGrid th > label {
    text-align: center;
    color: black;
}

table.SettlemementGrid td:first-child > label {
    text-align: left;
}

table.SettlemementGrid th:first-child > label {
    text-align: left;
}

table.SettlemementGrid td > label {
    text-align: center;
    color: black;
}

.TableContainer {
    width: calc(100vw - 33em);
    margin-bottom: 1em;
    border-radius: 5px;
    border: solid 1px lightgray;
    overflow: auto;    
    max-height: 20em;
}


table.SettlemementGrid th {
    min-width: 10em !important;
    /* max-width: 15em !important; */
    text-align: left;
}
  
td {
vertical-align: top;
}

table.SettlemementGrid th:first-child,
table.SettlemementGrid td:first-child {
width: auto;
min-width: unset !important;
}
  
  
  
/* table.SettlemementGrid th:last-child,
table.SettlemementGrid td:last-child {
position: sticky;
right: 0;
width: 1em;
} */

/* table.SettlemementGrid th:last-child:before,
table.SettlemementGrid td:last-child:before {
content: "\00a0";
display: block;
position: normal;
background: unset;
top: 0;
right: 0px;
bottom: 0;
left: 0;
z-index: -1;


} */
  
table.SettlemementGrid td:last-child:before {
box-shadow: none;
}
