.PlanDetailsHeader {
  margin: 10px;
}

.PlanDetailsHeader > small {
  font-size: 12px;
}

.PlanDetailsHeader > label {
  text-transform: uppercase;
}

.PlanDetailsHeader > label:last-child {
  text-transform: capitalize;
}
