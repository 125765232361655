.Controls {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

}

.primary.gray {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(115, 123, 126);
}

.primary.black {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(22, 22, 22);
}

.primary.red {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(214, 66, 66);
    color: rgb(214, 66, 66);
}

.primary.yellow {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(214, 204, 66);
}

.primary.green {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(81, 163, 49);
    color: rgb(81, 163, 49);
}

.primary.blue {
    /* background-color: rgb(225 219 219); */
    border-left: solid 5px rgb(66, 113, 214);
    color: rgb(66, 113, 214);
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

