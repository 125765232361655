.VisitasSection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    width: 100%;
}

.VisitasSection > div {
    width: 30%;
}

.VisitasSection > div:first-child {
    width: 70%;
}

.VisitasSection > div:first-child {
    margin-right: 1em;
}

.ContractPageContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.ContractPageContent > div {
    flex: 1;
}

.ContractPageContent > div:last-child {
    flex: unset;
    width: 20em;
}

