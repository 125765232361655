.MedicalTeamCard {
    margin-bottom: 0.5rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* box-shadow: 0 2px 3px #ccc; */
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    /* width: 100%; */
    /* background-color: #fff; */
    flex-direction: row;
    position: relative;
}

.MedicalTeamCard > div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
}

/* .MedicalTeamCard > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: flex-start;
    max-width: 10em;
} */

.MedicalTeamCard.principal {
    border: solid 1px rgb(78, 116, 165);
    background-color: rgb(244, 250, 255);
}

.MedicalTeamCard > .PractitionerInfo {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

}

.MedicalTeamCard > .PractitionerInfo > * {
    margin-right: 1em;
}

.MedicalTeamCard:hover {
    background-color: rgb(248, 248, 248);
    cursor: pointer;
}

.MedicalAcctionsSector {
    cursor: pointer;
    display: none;
    position: absolute;
    right: 0;
    width: 2em;
    visibility:hidden;
    opacity:0;
    
}

.MedicalTeamCard:hover > .PractitionerInfo > .MedicalAcctionsSector {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    transition: visibility 0.3s linear, opacity 0.3s linear;    
    transition-delay: 500ms;
    visibility: visible;
    opacity: 1;
    
}