.NoDataSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 20px;
    text-align: center;
    margin-bottom: 3em;
    margin-top: 3em;
}

.StageListItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
    padding: 0.5em;
    margin: 0.5em 0.5em 0 0;
    border: solid 1px #c0c0c0;
    border-radius: 5px;
    justify-content: space-between;
}

.StageListItem > div:last-child {
    flex: 1;
    align-self: self-end;
}

.StageListItem:hover {
    background-color: #f0f0f0;
}

.StageBadge {
    background-color: rgb(231, 231, 231);
    border-radius: 3px;
    padding: 0.3em 0.5em ;
    margin-right: .5em;
}

.StageListItemOptions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 15em;
}

.StageListItemOptions > div > div > label {
    margin-left: 1em;
}

.StageListItem > div > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.StageListItem > div > div > small {
    background-color: deepskyblue;
    border-radius: 3px;
    padding: .4em .6em;
    margin-right: .5em;
}

.StageListItemContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.StageListItemContent > div > span {
    margin-left: .5em;
    text-transform: initial;
}

.StageIndex{
    width: 2em;
    height: 100%;
    text-align: center;
    margin-left: -0.5em;
    border-right: solid 1px lightgray;
    margin-right: 0.5em;
}

.StageIndex > span{
    writing-mode: vertical-lr; 
    transform: rotate(180deg);
    height: 6em;
}