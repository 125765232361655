body {
  margin: 0;
  font-family: 'Lato', Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

#root > div:last-child {
  flex: 1;
}
