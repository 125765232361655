div:has(div.LoadingPayments) {
    position: relative;
}
.LoadingPayments {
    position: absolute;
    border: solid 1px #c1c1c1;
    display: flex;
    flex-direction: row;
    bottom: 10px;
    background-color: white;
    padding: 5px;
    border-radius: 7px 7px 0 0;
    border-bottom: none;
    left: calc((100vw - 19em) / 2);
    box-shadow: 7px -4px 16px -6px rgba(30,30,30,0.50);
    -webkit-box-shadow: 7px -4px 16px -6px rgba(30,30,30,0.50);
    -moz-box-shadow: 7px -4px 16px -6px rgba(30,30,30,0.50);
}

.NoData {
    font-size: 1.5em;
    color: #535353;
    padding: 5px 0 10px 0px;
    border-bottom: solid 1px #dbdbdb;
    margin-bottom: 20px;
}

.NoDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 1em;
}