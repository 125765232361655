.protocolTotals {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 10px;
    border: solid 1px #aeaeae;
    font-size: 1.2em;
    min-width: 10em;
    margin-right: 1em;
}